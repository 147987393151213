/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';

// UI lib comoponents
import useImage from '../../Helpers/useImage';

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */
function DynamicImage({ path, fileName, alt, className, ...rest }) {
  /* ******************************** HOOKS ******************************* */
  const { image } = useImage(path, fileName);
  /* ******************************** RENDERING ******************************* */

  if (!image) return null;
  return <img className={className} src={image} alt={alt} {...rest} />;
}

DynamicImage.propTypes = {
  path: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  className: PropTypes.string,
  alt: PropTypes.string,
};

DynamicImage.defaultProps = {
  className: '',
  alt: '',
};

export default DynamicImage;
