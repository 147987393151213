import React, { useEffect, useRef, useState } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';

import frIcon from '../../../images/fr-icon.svg';
import enIcon from '../../../images/en-icon.svg';
import arrow from '../../../images/arrow-white.svg';
import arrowDark from '../../../images/arrow-dark.svg';

import './index.scss';

function LanguageSelect({ mobile }) {
  const icons = {
    en: enIcon,
    fr: frIcon,
    ar: frIcon,
  };
  const [open, setOpen] = useState(false);
  const { language, changeLanguage } = useI18next();
  const optionsRef = useRef(null);

  function handleClick(lang) {
    changeLanguage(lang);
    setOpen(false);
  }

  function handleBlur(e) {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setOpen(false);
    }
  }

  useEffect(() => {
    if (open) {
      optionsRef.current.focus();
    }
  }, [open]);

  return (
    <div className="language-select" ref={optionsRef} onBlur={handleBlur}>
      <button type="button" className="value" onClick={() => setOpen(!open)}>
        <img src={icons[language]} alt="" className="lang-img" />
        <span className="span">{language}</span>
        <img src={mobile ? arrowDark : arrow} alt="" />
      </button>
      <ul className={`options ${open ? 'open' : ''}`}>
        <li>
          <button
            type="button"
            className="span"
            onClick={() => handleClick('fr')}
          >
            FR
          </button>
        </li>
        <li>
          <button
            type="button"
            className="span"
            onClick={() => handleClick('ar')}
          >
            AR
          </button>
        </li>
        <li>
          <button
            type="button"
            className="span"
            onClick={() => handleClick('en')}
          >
            EN
          </button>
        </li>
      </ul>
    </div>
  );
}

LanguageSelect.propTypes = {
  mobile: Boolean,
};

LanguageSelect.defaultProps = {
  mobile: false,
};
export default LanguageSelect;
