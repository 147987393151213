/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Link } from 'gatsby';

// UI lib comoponents
import { Container, Row } from 'react-grid-system';

// Local UI components
import DynamicImage from '../UIKit/DynamicImage';
import arrowdownwhite from '../../images/pageFooter/arrowdownwhite.png';
// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function PageFooter() {
  /* ******************************** CONSTANTS ******************************* */
  const initalShowSubmenuState = { show: false, id: undefined };

  /* ********************************** HOOKS ********************************* */
  const [showSubmenu, setShowSubmenu] = React.useState(initalShowSubmenuState);

  // disable/enable body scroll based on device
  if (typeof window !== 'undefined') {
    document.body.style.overflowY = 'hidden';
    document.documentElement.style.overflowY = 'auto';
  }

  // Localization
  const { t } = useTranslation();
  const { menu, srcglobal } = t('footer', { returnObjects: true });

  /* ***************************** LOCAL VARIABLES **************************** */

  /* ***************************** RENDER HELPERS ***************************** */
  function handleMouseEnter(id) {
    setShowSubmenu({ show: true, id });
  }

  function handleMouseLeave() {
    setShowSubmenu(initalShowSubmenuState);
  }
  function handleClick(e, id) {
    e.preventDefault();
    setShowSubmenu({ show: !showSubmenu?.show, id });
  }

  function Submenu({ submenu }) {
    return submenu?.map(({ id, name, url }) => (
      <li key={id} className="page-footer-menu__item-submenu__item">
        <Link to={url}>{name}</Link>
      </li>
    ));
  }

  const linksMap = menu.map(
    ({ id, url, item, hasSubmenu = false, submenu }) => (
      <li key={id} className="page-footer-menu__item">
        {hasSubmenu ? (
          <div
            role="menu"
            onMouseEnter={hasSubmenu ? () => handleMouseEnter(id) : undefined}
            onMouseLeave={hasSubmenu ? handleMouseLeave : undefined}
            tabIndex={0}
          >
            <div className="sub-link" role="menuitem">
              <Link to={url}>{item}</Link>
              <span
                tabIndex={0}
                role="button"
                className="arrow-down-container"
                aria-label="ouvrir le menu"
                onKeyDown={hasSubmenu ? (e) => handleClick(e, id) : undefined}
                onClick={hasSubmenu ? (e) => handleClick(e, id) : undefined}
              >
                <img src={arrowdownwhite} alt="" />
              </span>
            </div>
            <ul
              className={`page-footer-menu__item-submenu ${
                showSubmenu.id === id && showSubmenu.show ? 'active' : ''
              }`}
            >
              <Submenu submenu={submenu} />
            </ul>
          </div>
        ) : (
          <Link to={url}>{item}</Link>
        )}
      </li>
    ),
  );
  return (
    <Container className="page-footer" fluid>
      <Row>
        <div className="page-footer-logo">
          <DynamicImage
            path="pageFooter"
            fileName={srcglobal}
            alt="exactt logo"
          />
        </div>
      </Row>
      <Row className="page-footer-link" align="center" justify="around">
        <ul className="page-footer-link-ul">{linksMap}</ul>
      </Row>
    </Container>
  );
}

PageFooter.propTypes = {};

PageFooter.defaultProps = {};

export default PageFooter;
