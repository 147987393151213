/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { useScreenClass } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import { Link, navigate } from 'gatsby';
import DynamicImage from '../UIKit/DynamicImage';

// Style
import './index.scss';

// assets
import siteLogo from '../../images/pageHeader/logo-exactt.svg';
import MenuIcon from '../../images/pageHeader/menu-icon.svg';
import MenuIconClose from '../../images/pageHeader/menu-icon-close.svg';
import ArrowDown from '../../images/pageHeader/arrow-down.svg';
import LanguageSelect from '../UIKit/LanguageSelect';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function PageHeader() {
  /* ********************************** CONSTANTS ********************************* */
  const initalShowSubmenuState = { show: false, id: undefined };

  /* ********************************** HOOKS ********************************* */
  const [showSubmenu, setShowSubmenu] = React.useState(initalShowSubmenuState);
  const [showMobileMenu, setShowMobileMenu] = React.useState({
    state: false,
    interactivity: false,
  });

  const screenClass = useScreenClass();
  const IS_MOBILE = ['xs', 'sm'].includes(screenClass);

  // disable/enable body scroll based on device
  if (typeof window !== 'undefined') {
    if (IS_MOBILE) {
      if (showMobileMenu.state) {
        document.body.style.overflowY = 'hidden';
        document.documentElement.style.overflowY = 'hidden';
      } else if (!showMobileMenu.state) {
        document.body.style.overflowY = 'auto';
        document.documentElement.style.overflowY = 'auto';
      }
    } else {
      document.body.style.overflowY = 'auto';
      document.documentElement.style.overflowY = 'auto';
    }
  }

  // Localization
  const { t } = useTranslation();
  const {
    menu,
    ctaButton,
    banner: { bannerLinks, socialLinks },
  } = t('header', { returnObjects: true });

  /* ******************************** CALLBACK ******************************* */
  function handleMouseEnter(id) {
    setShowSubmenu({ show: true, id });
  }

  function handleMouseLeave() {
    setShowSubmenu(initalShowSubmenuState);
  }

  function handleClick(e, id) {
    e.preventDefault();
    setShowSubmenu({ show: !showSubmenu?.show, id });
  }

  function handleBlur() {
    setShowSubmenu(initalShowSubmenuState);
  }

  /* ******************************** RENDER HELPERS ******************************* */
  function BannerLinks() {
    return bannerLinks.map(({ id, value, url }) => (
      <Link
        key={id}
        to={url}
        className={`${
          showMobileMenu.state ? 'banner-mobileLink' : 'banner-link'
        } `}
      >
        {value}
      </Link>
    ));
  }

  function SocialLinks() {
    return socialLinks.map(({ id, url, icon }) => (
      <Link key={id} to={url} className="social-link-container">
        <DynamicImage path="pageHeader" fileName={icon} alt="fb page" />
      </Link>
    ));
  }

  function Submenu({ submenu }) {
    return submenu?.map(({ id, name, url }) => (
      <li key={id} className="page-header-menu__item-submenu__item">
        <Link to={url}>{name}</Link>
      </li>
    ));
  }

  function MenuItems() {
    return menu?.map(
      ({ id, name, url, hasSubmenu = false, submenu, isLink = false }) => (
        <li key={id} className="page-header-menu__item">
          {hasSubmenu ? (
            <div
              role="menu"
              onMouseEnter={
                hasSubmenu && !IS_MOBILE
                  ? () => handleMouseEnter(id)
                  : undefined
              }
              onMouseLeave={
                hasSubmenu && !IS_MOBILE ? handleMouseLeave : undefined
              }
              onBlur={hasSubmenu && IS_MOBILE ? handleBlur : undefined}
              tabIndex={0}
            >
              <div className="sub-link" role="menuitem">
                {isLink ? <Link to={url}>{name}</Link> : name}
                <span
                  tabIndex={0}
                  role="button"
                  className="arrow-down-container"
                  aria-label="ouvrir le menu"
                  onKeyDown={
                    hasSubmenu && IS_MOBILE
                      ? (e) => handleClick(e, id)
                      : undefined
                  }
                  onFocus={
                    hasSubmenu && IS_MOBILE
                      ? (e) => handleClick(e, id)
                      : undefined
                  }
                  onClick={
                    hasSubmenu && IS_MOBILE
                      ? (e) => handleClick(e, id)
                      : undefined
                  }
                >
                  <img src={ArrowDown} alt="" />
                </span>
              </div>
              <ul
                className={`page-header-menu__item-submenu ${
                  showSubmenu.id === id && showSubmenu.show ? 'active' : ''
                }`}
              >
                <Submenu submenu={submenu} />
              </ul>
            </div>
          ) : (
            <Link to={url}>{name}</Link>
          )}
        </li>
      ),
    );
  }

  /* ******************************** RENDERING ******************************* */
  return (
    <header className="page-header">
      <div className="banner">
        <LanguageSelect />
        <div className="banner-links">
          <BannerLinks />
        </div>
        <div className="social-links">
          <SocialLinks />
        </div>
      </div>
      <div className="page-header-container">
        <div className="page-header-logo-container">
          <Link to="/">
            <img src={siteLogo} alt="Exactt logo" />
          </Link>
        </div>
        <nav
          className={`page-header-nav ${showMobileMenu.state ? 'open' : ''} ${
            !showMobileMenu.state && showMobileMenu.interactivity ? 'idle' : ''
          }`}
        >
          <ul className="page-header-menu">
            <MenuItems />
            {showMobileMenu.state ? <BannerLinks /> : ''}
          </ul>
          <button
            type="button"
            className="page-header__cta-button"
            onClick={() => navigate(ctaButton.url)}
          >
            {ctaButton.value}
          </button>

          <div className="language-mobile">
            <LanguageSelect mobile />
          </div>
        </nav>
        <div className="page-header-menu-icon">
          <button
            type="button"
            className="page-header-menu-toggle-button"
            onClick={() => {
              setShowMobileMenu({
                state: !showMobileMenu.state,
                interactivity: true,
              });
            }}
          >
            <img src={showMobileMenu.state ? MenuIconClose : MenuIcon} alt="" />
          </button>
        </div>
      </div>
    </header>
  );
}
PageHeader.propTypes = {};

PageHeader.defaultProps = {};

export default PageHeader;
